<template lang="html">
  <div :class="$style.table">
    <SimpleTable
      :columns="columns" :data="relevantWorkspaces"
      localize-headers
    >
      <template #activationState="{value, row: {id}}">
        <WorkspaceActivationStateInput
          :value="value"
          @input="onInput({id, desiredState: $event})"
        />
      </template>
    </SimpleTable>
  </div>
</template>

<script>
import {fromPairs} from 'lodash'

import SimpleTable from '@components/table/SimpleTable'

import WorkspaceActivationStateInput from './WorkspaceActivationStateInput'

export default {
  components: {
    SimpleTable,
    WorkspaceActivationStateInput
  },
  props: {
    workspaces: {
      type: Array,
      required: true
    },
    activeWorkspaces: {
      type: Array,
      required: true
    }
  },
  computed: {
    columns () {
      return {
        name: 'administration.powerBi.workspaces.labels.name',
        activationState: 'administration.powerBi.workspaces.labels.activationState'
      }
    },
    roleRequiredLookup () {
      return fromPairs(this.activeWorkspaces.map(x => [x.id, x.roleRequired]))
    },
    relevantWorkspaces () {
      return this.workspaces
        .map(({id, name}) => ({
          id,
          name,
          activationState: this.roleRequiredLookup[id] === undefined
            ? 'disabled'
            : this.roleRequiredLookup[id]
              ? 'roleRequired'
              : 'enabled'
        }))
    }
  },
  methods: {
    onInput ({id, desiredState}) {
      if (desiredState === 'disabled') {
        this.$emit('disable', {id})
      } else {
        this.$emit('enable', {id, roleRequired: desiredState === 'roleRequired'})
      }
    }
  }
}
</script>

<style lang="scss" module>
.table tr > td:first-child {
  vertical-align: middle;
}
</style>
