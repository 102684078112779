<template lang="html">
  <div>
    <span :class="$style.prefix">power-bi-workspace/</span>
    <span :class="$style.prefix">{{ workspaceId }}-</span>
    <span>{{ roleNameSuffix }}</span>
  </div>
</template>

<script>
export default {
  props: {
    workspaceId: String,
    roleNameSuffix: String
  }
}
</script>

<style lang="scss" module>
.prefix {
  opacity: 0.5;
}
</style>
