<template lang="html">
  <div>
    <HelpBox>
      <IxRes>administration.powerBi.workspaces.hints.reports</IxRes>
    </HelpBox>

    <SimpleTable
      :class="$style.table"
      :columns="columns" :data="sortedData"
      localize-headers :highlight="highlight"
    >
      <template #name="{value, row}">
        <div :class="$style.name">
          <span>
            <FontAwesomeIcon :icon="icons[row.type]" />
            {{ value }}
          </span>

          <ReportIcons v-if="row.imported" :report="row" />
        </div>
      </template>
      <template #workspaceId="{value}">
        {{ workspaceNameLookup[value] }}
      </template>
      <template #roleNameSuffix="{value}">
        <ReportRoleName v-if="value" :role-name="value" />
      </template>
      <template #actions="{row: report}">
        <template v-if="importedWorkspaces.find(x => x.id === report.workspaceId)">
          <SimpleTableAction @click="$refs.editModal.start(report)">
            <IxRes>administration.powerBi.reports.buttons.{{ report.imported ? 'edit' : 'import' }}</IxRes>
          </SimpleTableAction>
          <SimpleTableAction v-if="report.imported" @click="$refs.removeModal.start(report)">
            <IxRes>administration.powerBi.reports.buttons.remove</IxRes>
          </SimpleTableAction>
        </template>
      </template>
    </SimpleTable>

    <ReportRemoveModal ref="removeModal" @confirm="$emit('remove', $event)" />
    <ReportEditModal ref="editModal" :existing-roles="existingRoles" @finish="$emit('update', $event)" />
  </div>
</template>

<script>
import {sortBy, fromPairs} from 'lodash'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faChartLine, faTachometerAlt} from '@fortawesome/free-solid-svg-icons'

import SimpleTable from '@components/table/SimpleTable'
import SimpleTableAction from '@components/table/SimpleTableAction'
import HelpBox from '@components/help/HelpBox'

import ReportIcons from './ReportIcons'
import ReportRoleName from './ReportRoleName'
import ReportEditModal from './ReportEditModal'
import ReportRemoveModal from './ReportRemoveModal'

export default {
  components: {
    SimpleTable,
    HelpBox,
    ReportIcons,
    ReportRoleName,
    FontAwesomeIcon,
    SimpleTableAction,
    ReportEditModal,
    ReportRemoveModal
  },
  props: {
    workspaces: {
      type: Array,
      required: true
    },
    importedWorkspaces: {
      type: Array,
      required: true
    },
    reports: {
      type: Array,
      required: true
    },
    existingRoles: {
      type: Array,
      required: true
    }
  },
  computed: {
    workspaceNameLookup () {
      return fromPairs(this.workspaces.map(x => [x.id, x.name]))
    },
    sortedData () {
      return sortBy(this.reports, ['workspaceId', 'name'])
    },
    columns () {
      return {
        name: {
          title: 'administration.powerBi.reports.labels.name',
          sortable: true,
          type: 'string'
        },
        workspaceId: {
          title: 'administration.powerBi.reports.labels.workspaceId',
          sortable: true,
          type: 'string',
          sortingFunction: (a, b) => this.workspaces.find(x => x.id === a).name.localeCompare(this.workspaces.find(x => x.id === b).name)
        },
        description: 'administration.powerBi.reports.labels.description',
        id: 'administration.powerBi.reports.labels.id',
        roleNameSuffix: 'administration.powerBi.reports.labels.roleNameSuffix',
        actions: ''
      }
    },
    icons () {
      return {
        dashboard: faTachometerAlt,
        report: faChartLine
      }
    }
  },
  methods: {
    highlight (row) {
      if (row.visible) return 'success'
      if (row.imported) return 'warning'
    }
  }
}
</script>

<style lang="scss" module>
.table tr > td:nth-child(3) {
  max-width: 300px;
}

.name {
  display: flex;
  justify-content: space-between;
}
</style>
