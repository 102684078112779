<template lang="html">
  <div :class="$style.footer">
    <IxButton large @click="$emit('confirm')">
      <slot name="confirm" />
    </IxButton>
    <IxButton large @click="$emit('cancel')">
      <slot name="cancel" />
    </IxButton>
  </div>
</template>

<script>
import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton
  }
}
</script>

<style lang="scss" module>
.footer {
  display: flex;
  justify-content: space-between;
}
</style>
