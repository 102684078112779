<template lang="html">
  <AsyncModal ref="modal" @finish="$emit('confirm', $event)">
    <template #title>
      <IxRes>{{ translationNamespace }}.{{ action }}ConfirmationModal.header</IxRes>
    </template>

    <slot v-bind="context">
      <IxRes>{{ translationNamespace }}.{{ action }}ConfirmationModal.text</IxRes>
    </slot>

    <template #footer="{finish, cancel}">
      <ConfirmationModalFooter @confirm="finish(context)" @cancel="cancel">
        <template #confirm>
          <IxRes>{{ translationNamespace }}.{{ action }}ConfirmationModal.confirm</IxRes>
        </template>
        <template #cancel>
          <IxRes>{{ translationNamespace }}.{{ action }}ConfirmationModal.cancel</IxRes>
        </template>
      </ConfirmationModalFooter>
    </template>
  </AsyncModal>
</template>

<script>
import AsyncModal from '@components/modals/AsyncModal'
import ConfirmationModalFooter from './ConfirmationModalFooter'

export default {
  components: {
    AsyncModal,
    ConfirmationModalFooter
  },
  props: {
    translationNamespace: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      context: null
    }
  },
  methods: {
    // NOTE context must be an Object because it is used as a slot scope
    async start (context) {
      this.context = context
      return this.$refs.modal.start()
    }
  }
}
</script>

<style lang="scss" module>

</style>
