<template lang="html">
  <BsModal v-model="show">
    <template v-if="$scopedSlots.title" #title>
      <slot name="title" />
    </template>

    <slot />

    <template #footer>
      <slot name="footer" v-bind="{finish, cancel}" />
    </template>
  </BsModal>
</template>

<script>
import BsModal from '@components/bootstrap/BsModal'

export default {
  components: {
    BsModal
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    async start () {
      this.show = true

      const {finished, result} = await new Promise(resolve => {
        this._resolve = resolve
      })

      if (finished) {
        this.$emit('finish', result)
      } else {
        this.$emit('cancel')
      }

      this.show = false

      return {finished, result}
    },
    async finish (result) {
      this._resolve({
        finished: true,
        result
      })
    },
    cancel () {
      this._resolve({finished: false})
    }
  }
}
</script>

<style lang="scss" module>

</style>
