<template lang="html">
  <h3>
    <slot />
  </h3>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>
