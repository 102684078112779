<template lang="html">
  <AsyncConfirmationModal
    ref="modal"
    translation-namespace="administration.powerBi.workspaces" action="remove"
    v-on="$listeners"
  >
    <template #default="{name}">
      <IxRes :context="{name}">administration.powerBi.workspaces.hints.removeWorkspace</IxRes>
    </template>
  </AsyncConfirmationModal>
</template>

<script>
import AsyncConfirmationModal from '@components/modals/AsyncConfirmationModal'

export default {
  components: {
    AsyncConfirmationModal
  },
  methods: {
    start (workspace) {
      return this.$refs.modal.start(workspace)
    }
  }
}
</script>

<style lang="scss" module>

</style>
