<template lang="html">
  <div class="btn-group">
    <ToggleButton v-model="modelProxy" value="disabled" required>
      <IxRes>administration.powerBi.workspaces.activationState.disabled</IxRes>
    </ToggleButton>
    <ToggleButton v-model="modelProxy" value="roleRequired" required>
      <IxRes>administration.powerBi.workspaces.activationState.roleRequired</IxRes>
    </ToggleButton>
    <ToggleButton v-model="modelProxy" value="enabled" required>
      <IxRes>administration.powerBi.workspaces.activationState.enabled</IxRes>
    </ToggleButton>
  </div>
</template>

<script>
import {modelProxy} from '@helpers/vuex'

import ToggleButton from '@components/ToggleButton'

export default {
  components: {
    ToggleButton
  },
  props: {
    value: String
  },
  computed: {
    modelProxy
  }
}
</script>

<style lang="scss" scoped>
</style>
