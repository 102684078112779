<template lang="html">
  <AsyncConfirmationModal
    ref="modal"
    translation-namespace="administration.powerBi.reports" action="remove"
    v-on="$listeners"
  >
    <template #default="{name}">
      <IxRes :context="{name}">administration.powerBi.reports.hints.removeReport</IxRes>
    </template>
  </AsyncConfirmationModal>
</template>

<script>
import AsyncConfirmationModal from '@components/modals/AsyncConfirmationModal'

export default {
  components: {
    AsyncConfirmationModal
  },
  methods: {
    start (report) {
      return this.$refs.modal.start(report)
    }
  }
}
</script>

<style lang="scss" module>

</style>
