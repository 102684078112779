<template lang="html">
  <div :class="$style.icons">
    <FontAwesomeIcon v-if="report.showNav" :icon="icons.showNav" />
    <FontAwesomeIcon v-if="report.showFilters" :icon="icons.showFilters" />
    <FontAwesomeIcon v-if="report.theme" :icon="icons.theme" />

    <FontAwesomeIcon :icon="report.visible ? icons.visible : icons.invisible" />
  </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faEye, faEyeSlash, faFilter, faSlidersH, faPalette} from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  computed: {
    icons () {
      return {
        showNav: faSlidersH,
        showFilters: faFilter,
        visible: faEye,
        invisible: faEyeSlash,
        theme: faPalette
      }
    }
  }
}
</script>

<style lang="scss" module>
.icons {
  > :not(:first-child) {
    margin-left: 0.25em;
  }
}
</style>
