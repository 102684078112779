<template lang="html">
  <div>
    <HelpBox>
      <IxRes>administration.powerBi.workspaces.hints.workspaces</IxRes>
    </HelpBox>

    <SimpleTable
      :columns="columns" :data="sortedData"
      localize-headers :highlight="highlight"
    >
      <template #roleName="{value, row: {id}}">
        <WorkspaceRoleName v-if="value" :workspace-id="id" :role-name-suffix="value" />
      </template>
      <template #actions="{row: workspace}">
        <SimpleTableAction v-if="workspace.roleNameSuffix" @click="$refs.removeModal.start(workspace)">
          <IxRes>administration.powerBi.workspaces.buttons.remove</IxRes>
        </SimpleTableAction>
        <SimpleTableAction v-else @click="$refs.importModal.start(workspace)">
          <IxRes>administration.powerBi.workspaces.buttons.import</IxRes>
        </SimpleTableAction>
      </template>
    </SimpleTable>

    <WorkspaceRemoveModal ref="removeModal" @confirm="$emit('remove', $event)" />
    <WorkspaceImportModal ref="importModal" @finish="$emit('import', $event)" />
  </div>
</template>

<script>
import {sortBy} from 'lodash'

import HelpBox from '@components/help/HelpBox'
import SimpleTable from '@components/table/SimpleTable'
import SimpleTableAction from '@components/table/SimpleTableAction'

import WorkspaceRoleName from './WorkspaceRoleName'
import WorkspaceRemoveModal from './WorkspaceRemoveModal'
import WorkspaceImportModal from './WorkspaceImportModal'

export default {
  components: {
    HelpBox,
    SimpleTable,
    WorkspaceRoleName,
    SimpleTableAction,
    WorkspaceRemoveModal,
    WorkspaceImportModal
  },
  props: {
    workspaces: {
      type: Array,
      required: true
    }
  },
  computed: {
    columns () {
      return {
        name: 'administration.powerBi.workspaces.labels.name',
        id: 'administration.powerBi.workspaces.labels.id',
        roleNameSuffix: 'administration.powerBi.workspaces.labels.fullRoleName',
        actions: ''
      }
    },
    sortedData () {
      return sortBy(this.workspaces, ['name'])
    }
  },
  methods: {
    highlight (row) {
      if (row.roleNameSuffix) return 'success'
    }
  }
}
</script>

<style lang="scss" module>

</style>
