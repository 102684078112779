var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('HelpBox',[_c('IxRes',[_vm._v("administration.powerBi.workspaces.hints.reports")])],1),_c('SimpleTable',{class:_vm.$style.table,attrs:{"columns":_vm.columns,"data":_vm.sortedData,"localize-headers":"","highlight":_vm.highlight},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [_c('div',{class:_vm.$style.name},[_c('span',[_c('FontAwesomeIcon',{attrs:{"icon":_vm.icons[row.type]}}),_vm._v("\n          "+_vm._s(value)+"\n        ")],1),(row.imported)?_c('ReportIcons',{attrs:{"report":row}}):_vm._e()],1)]}},{key:"workspaceId",fn:function(ref){
var value = ref.value;
return [_vm._v("\n      "+_vm._s(_vm.workspaceNameLookup[value])+"\n    ")]}},{key:"roleNameSuffix",fn:function(ref){
var value = ref.value;
return [(value)?_c('ReportRoleName',{attrs:{"role-name":value}}):_vm._e()]}},{key:"actions",fn:function(ref){
var report = ref.row;
return [(_vm.importedWorkspaces.find(function (x) { return x.id === report.workspaceId; }))?[_c('SimpleTableAction',{on:{"click":function($event){return _vm.$refs.editModal.start(report)}}},[_c('IxRes',[_vm._v("administration.powerBi.reports.buttons."+_vm._s(report.imported ? 'edit' : 'import'))])],1),(report.imported)?_c('SimpleTableAction',{on:{"click":function($event){return _vm.$refs.removeModal.start(report)}}},[_c('IxRes',[_vm._v("administration.powerBi.reports.buttons.remove")])],1):_vm._e()]:_vm._e()]}}])}),_c('ReportRemoveModal',{ref:"removeModal",on:{"confirm":function($event){return _vm.$emit('remove', $event)}}}),_c('ReportEditModal',{ref:"editModal",attrs:{"existing-roles":_vm.existingRoles},on:{"finish":function($event){return _vm.$emit('update', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }