<template lang="html">
  <AsyncModal ref="modal" v-on="$listeners">
    <template #title>
      <IxRes>administration.powerBi.workspaces.headers.import</IxRes>
    </template>

    <template v-if="workspace">
      <TextInput
        v-model="roleNameSuffix" name="roleNameSuffix"
        addon-left="power-bi-workspace/"
        required
      >
        <template #label>
          <IxRes>administration.powerBi.workspaces.labels.roleNameSuffix</IxRes>
        </template>
      </TextInput>
    </template>

    <template #footer="{finish, cancel}">
      <ConfirmationModalFooter @confirm="doIfValid(() => finish({...workspace, roleNameSuffix}))" @cancel="cancel">
        <template #confirm>
          <IxRes>administration.powerBi.workspaces.buttons.confirmImport</IxRes>
        </template>
        <template #cancel>
          <IxRes>administration.powerBi.workspaces.buttons.cancelImport</IxRes>
        </template>
      </ConfirmationModalFooter>
    </template>
  </AsyncModal>
</template>

<script>
import TextInput from '@components/forms/TextInput'

import FormPartMixin from '@components/forms/FormPartMixin'
import ChangeDetectionMixin from '@components/forms/ChangeDetectionMixin'
import AsyncModal from '@components/modals/AsyncModal'
import ConfirmationModalFooter from '@components/modals/ConfirmationModalFooter'

export default {
  components: {
    TextInput,
    AsyncModal,
    ConfirmationModalFooter
  },
  mixins: [
    FormPartMixin,
    ChangeDetectionMixin
  ],
  data () {
    return {
      workspace: null,
      roleNameSuffix: null,
      resolve: null
    }
  },
  methods: {
    async start (workspace) {
      this.workspace = workspace
      this.roleNameSuffix = null
      this.reset()

      return this.$refs.modal.start()
    }
  }
}
</script>

<style lang="scss" module>

</style>
