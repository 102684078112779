<template lang="html">
  <AsyncModal ref="modal" v-on="$listeners">
    <template #title>
      <IxRes v-if="report && report.imported">administration.powerBi.reports.headers.edit</IxRes>
      <IxRes v-else>administration.powerBi.reports.headers.import</IxRes>
    </template>

    <label>
      <IxRes>administration.powerBi.reports.labels.type</IxRes>
    </label>

    <p>
      <IxRes>administration.powerBi.reports.labels.type_{{ report && report.type }}</IxRes>
    </p>

    <label>
      <IxRes>administration.powerBi.reports.labels.name</IxRes>
    </label>

    <p>{{ report && report.name }}</p>

    <SimpleSwitch v-model="editable.visible">
      <IxRes>administration.powerBi.reports.labels.visible</IxRes>
    </SimpleSwitch>

    <TextInput v-model="editable.description" multiline :class="$style.input">
      <template #label>
        <IxRes>administration.powerBi.reports.labels.description</IxRes>
      </template>
    </TextInput>

    <TextInput v-model="editable.roleNameSuffix" :class="$style.input" addon-left="power-bi-reports/">
      <template #label>
        <IxRes>administration.powerBi.reports.labels.roleNameSuffix</IxRes>
      </template>
    </TextInput>

    <HelpBox v-if="editable.roleNameSuffix && !existingRoles.includes(editable.roleNameSuffix)" type="warning">
      <IxRes :context="{role: editable.roleNameSuffix}">
        administration.powerBi.reports.hints.roleWillBeCreated
      </IxRes>
    </HelpBox>

    <template v-if="report && report.type === 'report'">
      <SimpleSwitch v-model="editable.showNav">
        <IxRes>administration.powerBi.reports.labels.showNav</IxRes>
      </SimpleSwitch>
      <SimpleSwitch v-model="editable.showFilters">
        <IxRes>administration.powerBi.reports.labels.showFilters</IxRes>
      </SimpleSwitch>
    </template>

    <template #footer="{finish, cancel}">
      <!-- NOTE no validation required because all form fields are optional -->
      <ConfirmationModalFooter @confirm="finish(editedReport)" @cancel="cancel">
        <template #confirm>
          <IxRes v-if="report && report.imported">administration.powerBi.reports.buttons.confirmSave</IxRes>
          <IxRes v-else>administration.powerBi.reports.buttons.confirmImport</IxRes>
        </template>

        <template #cancel>
          <IxRes v-if="report && report.imported">administration.powerBi.reports.buttons.cancelSave</IxRes>
          <IxRes v-else>administration.powerBi.reports.buttons.cancelImport</IxRes>
        </template>
      </ConfirmationModalFooter>
    </template>
  </AsyncModal>
</template>

<script>
import AsyncModal from '@components/modals/AsyncModal'
import ConfirmationModalFooter from '@components/modals/ConfirmationModalFooter'

import TextInput from '@components/forms/TextInput'
import SimpleSwitch from '@components/forms/SimpleSwitch'
import HelpBox from '@components/help/HelpBox'

const defaults = type => ({
  description: null,
  showNav: type === 'report',
  showFilters: type === 'report',
  roleNameSuffix: null,
  theme: null,
  visible: true
})

export default {
  components: {
    AsyncModal,
    ConfirmationModalFooter,
    TextInput,
    SimpleSwitch,
    HelpBox
  },
  props: {
    existingRoles: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      report: null,
      editable: defaults()
    }
  },
  computed: {
    editedReport () {
      return {
        ...this.report,
        imported: true,
        ...this.editable
      }
    }
  },
  methods: {
    start (report) {
      this.report = report

      if (report.imported) {
        for (const key in this.editable) {
          this.editable[key] = report[key]
        }
      } else {
        this.editable = defaults(report.type)
      }

      return this.$refs.modal.start()
    }
  }
}
</script>

<style lang="scss" module>
.input:not(:last-child) {
  margin-bottom: 0.5em;
}
</style>
