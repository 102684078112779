<template lang="html">
  <AdministrationLayout>
    <template #header>
      <IxRes>administration.powerBi.header</IxRes>
    </template>

    <AdministrationSectionHeader>
      <IxRes :context="{portal: portalId}">administration.powerBi.sections.portalSettings</IxRes>
    </AdministrationSectionHeader>
    <WorkspaceActivationList
      :workspaces="importedWorkspaces"
      :active-workspaces="activeWorkspaces"
      @enable="enableWorkspace"
      @disable="disableWorkspace"
    />

    <AdministrationSectionHeader>
      <IxRes>administration.powerBi.sections.workspaces</IxRes>
    </AdministrationSectionHeader>

    <WorkspaceList
      :workspaces="workspaces"
      @remove="removeWorkspace"
      @import="importWorkspace"
    />

    <AdministrationSectionHeader>
      <IxRes>administration.powerBi.sections.reports</IxRes>
    </AdministrationSectionHeader>

    <ReportList
      :workspaces="workspaces"
      :imported-workspaces="importedWorkspaces"
      :reports="reports"
      :existing-roles="existingReportRoles"
      @remove="removeReport"
      @update="updateReport"
    />
  </AdministrationLayout>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

import {config} from 'src/js/infrastructure'

import AdministrationLayout from './components/AdministrationLayout'
import AdministrationSectionHeader from './components/AdministrationSectionHeader'

import WorkspaceActivationList from './components/power-bi/WorkspaceActivationList'
import WorkspaceList from './components/power-bi/WorkspaceList'
import ReportList from './components/power-bi/ReportList'

export default {
  components: {
    AdministrationLayout,
    AdministrationSectionHeader,
    WorkspaceActivationList,
    WorkspaceList,
    ReportList
  },
  computed: {
    ...mapState('administration/powerBi', [
      'workspaces',
      'reports'
    ]),
    ...mapGetters('administration/powerBi', [
      'importedWorkspaces',
      'activeWorkspaces',
      'existingReportRoles'
    ]),
    portalId () {
      return config.portalId
    }
  },
  methods: {
    ...mapActions('administration/powerBi', [
      'enableWorkspace',
      'disableWorkspace',
      'importWorkspace',
      'removeWorkspace',
      'updateReport',
      'removeReport'
    ])
  },
  async created () {
    this.$store.dispatch('administration/powerBi/load')
  }
}
</script>

<style lang="scss" scoped>
</style>
